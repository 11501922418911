
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.blog-archive,
[class*='blog-archive--'] {
  overflow: hidden;

  .card-small {
    background-color: $white !important;
  }

  @include mq(l) {
    [class*='section-row'][class*='is-net'] {
      .section-row__picture {
        margin-bottom: 0;
      }
    }

    [class*='section-row'][class*='is-net']:not(:first-of-type) {
      padding-top: 5rem !important;
    }
  }
}

.blog-archive__hero {
  overflow: visible !important;
  height: auto !important;
  min-height: auto !important;
  margin-top: 5rem !important;
  color: $white;
  background-color: $c-blue-dark;
}

.section-hero__content {
  width: auto;
}

.blog-archive__categories {
  max-width: 40rem;
  margin: 2rem;
  margin-left: 0;
}

.blog-archive__hero__content__anchor {
  @extend %fw-bold;

  display: inline-flex;
  align-items: center;
  color: $c-blue-dark;
  line-height: 1;
  text-decoration: none;

  &:first-child {
    margin-right: $spacing * 1.5;
  }

  .icon {
    width: 3rem;
    height: 4rem;
    margin-right: $spacing;
    fill: $c-gray-darkest;
  }
}

.blog-archive__double-section {
  overflow: hidden;
  border-top: 0.1rem solid $c-blue-light-pale;
  border-bottom: 0.1rem solid $c-blue-light-pale;
}

.blog-archive__double-section__inner {
  @include mq($until: xl) {
    padding-right: 0;
    padding-left: 0;

    .section-row:not(:last-child) {
      border-bottom: 0.1rem solid $c-blue-light-pale;
    }
  }

  @include mq(xl) {
    display: flex;

    .section-row:first-child {
      border-right: 0.1rem solid $c-blue-light-pale;
    }

    .section-row:last-child {
      overflow: visible;
      padding-left: col(1);

      .section-row__picture {
        right: -5rem;
      }
    }
  }
}

.blog-archive__more {
  display: flex;
  margin: 2rem auto;
}

.card-small-item__link,
.steps__item__link {
  @extend %fw-bold;

  display: block;
  font-size: 1.6rem;

  &:hover {
    text-decoration: none;
  }

  @include mq(m) {
    font-size: 1.8rem;
  }
}

.blog-archive__help {
  @include bg-color;
}

.card-small__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6rem;
  justify-content: center;
  margin: 3rem 2rem;

  @include mq(s) {
    grid-template-columns: 1fr 1fr;
    margin: 7.6rem 0;
  }

  @include mq(xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.card-small-item {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  text-align: left;

  &::v-deep.card-small-item__title {
    text-align: left;
  }

  &.highlighted {
    @include mq(xl) {
      grid-column: 1 / 3;
    }
  }
}

.card-small-item__title {
  margin-top: $spacing / 2;
  text-align: left;
}

.blog-archive__back {
  display: flex;
  align-items: center;
  text-decoration: none;

  .icon {
    width: 4rem;
    height: 4rem;
    transform: rotate(90deg);
    fill: $c-light;
  }

  .voo-link {
    margin-left: 1.2rem;
    color: $c-light;
  }
}
